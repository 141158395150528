import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { formatCurrency } from '@angular/common';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  iconsPath = environment.iconsPath;

  constructor(
    private i18n: TranslateService,
  ) { }

  public getExtensionIcon(extension: string): string{
    let mRet = '';
    switch (extension){
      case('.pdf'):
        mRet = 'file-pdf.png';
        break;
      case('.png'):
        mRet = 'file-jpeg.png';
        break;
      case('.docx'):
        mRet = 'file-word.png';
        break;
      case('.xlsx'):
        mRet = 'file-excel.png';
        break;
      case('.jpeg'):
        mRet = 'file-jpeg.png';
        break;
      case('.jpg'):
        mRet = 'file-jpeg.png';
        break;
      default:
        mRet = 'file-default.png';
        break;
    }

    return this.iconsPath + '/' + mRet;
  }

  public getDepartmentIcon(departmentId: any): string{
    let mRet = '';
    switch (departmentId){
      case(1):
        mRet = 'fal fa-chair-office';
        break;
      case(2):
        mRet = 'fal fa-vial';
        break;
      case(3):
        mRet = 'fal fa-laptop-house';
        break;
      default:
        mRet = 'fal fa-chair-office';
        break;
    }

    return mRet;
  }

}
