import { Routes } from '@angular/router';
import { ErrorPageComponent } from '../pages/error-page/error-page.component';


const routes: Routes = [
  { path: 'auth', loadChildren: () => import('../pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

export const generalRoutes = routes;
