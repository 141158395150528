import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSpanComponent } from 'src/app/components/profile-span/profile-span.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [ProfileSpanComponent],
  exports: [ProfileSpanComponent, TranslateModule],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class SharedModule { }
