<nav class="navbar">
  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
    <i class="feather icon-menu"></i>
  </a>
  <div class="navbar-content">
    <!-- <form class="search-form">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="feather icon-search"></i>
          </div>
        </div>
        <input type="text" class="form-control" id="navbarForm" placeholder="Search here...">
      </div>
    </form> -->
    <ul class="navbar-nav">

      <!-- Web Applications -->
      <!-- <li class="nav-item nav-apps" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="appsDropdown">
          <i class="link-icon feather icon-grid"></i>
        </a>
        <div ngbDropdownMenu aria-labelledby="appsDropdown">
          <div class="dropdown-header d-flex align-items-center justify-content-between">
            <p class="mb-0 font-weight-medium">Web Apps</p>
            <a href="" (click)="false" class="text-muted">Edit</a>
          </div>
          <div class="dropdown-body">
            <div class="d-flex align-items-center apps">
              <a routerLink="/apps/chat"><i class="feather icon-message-square"></i><p>Chat</p></a>
              <a routerLink="/apps/calendar"><i class="feather icon-calendar"></i><p>Calendar</p></a>
              <a routerLink="/apps/email/inbox"><i class="feather icon-mail"></i><p>Email</p></a>
              <a routerLink="/general/profile"><i class="feather icon-instagram"></i><p>Profile</p></a>
            </div>
          </div>

        </div>
      </li> -->

      <!-- Profile -->
      <li class="nav-item nav-profile">
        <div class="nav-link" >          
          <div class="row">            
            
            <div class="col-md-2 mt-2 text-right">             
              <img class="avatar" referrerpolicy="no-referrer" src="{{user?.userPhoto}}" alt="{{user?.firstname}} {{user?.lastname}}">
            </div>

            <div class="col-md-6 mt-2 text-left">
              <div class="small strong">{{user.firstname}} {{user.lastname}}</div>
              <h6><span routerLink="/profile" class="badge area rounded-pill badge-warning">{{user?.area?.name}} <span *ngIf="user?.level && user?.area">-</span> {{user?.level?.name}}</span></h6>
              <h6><span class="small"><i class="{{iconService.getDepartmentIcon(user?.departmentId)}}"></i> {{user?.department?.name}}</span></h6>             
            </div>
            
            <div class="col-md-4 mt-2 text-right small grey">   
              <div>
                <a routerLink="/profile">
                  <i class="feather icon-user"></i>
                  <span>{{'Edit Profile' | translate}}</span>
                </a>
              </div>           
              <div>
                <a (click)="onLogout($event)">
                  <i class="feather feather icon-log-out mr-2"></i>
                  <span>{{'Log Out' | translate}}</span>
                </a>
              </div>
              
            </div>
          
          </div>
          
        </div>

        
      </li>
    </ul>
  </div>
</nav>
