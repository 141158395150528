import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  monarApp: any = environment.monarApp;
  monarAppId: any = environment.monarAppId;
  monarConsole: any = environment.monarConsole;
  monar: any;

  constructor() {
    // this.monar = require('@armadilloamarillo/monarlib').init(this.monarApp, this.monarAppId, this.monarConsole);
  }

  _error(level, title, message): any {
    // switch(level){
    //   case('debug'):
    //     this.monar.debug(title, message);
    //   break;
    //   case('info'):
    //     this.monar.info(title, message);
    //   break;
    //   case('warning'):
    //     this.monar.warning(title, message);
    //   break;
    //   case('error'):
    //     this.monar.error(title, message);
    //   break;
    //   case('critical'):
    //     this.monar.critical(title, message);
    //   break;
    //   default:
    //     this.monar.debug(title, message);
    //   break;
    // }
  }

}
