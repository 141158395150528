<nav class="sidebar">
  <div class="sidebar-header">

    <!--- Logo -->
    <a routerLink="/" class="sidebar-brand">
      <img class="hanna-logo" src="assets/images/logo-white.png"/>   
    </a>

    <!--- Toggler -->
    <div class="sidebar-toggler not-active" #sidebarToggler (click)="toggleSidebar($event)">
      <span></span>
      <span></span>
      <span></span>
    </div>

  </div>
  <div class="sidebar-body" (mouseenter)="operSidebarFolded()" (mouseleave)="closeSidebarFolded()" [perfectScrollbar]>

    <!--- Sidemenu start -->

    <ul class="sidebar-nav metismenu" id="sidebar-menu" #sidebarMenu>
      <ng-container *ngFor="let item of menuItems">
        <hr class="light mtb-20" *ngIf="item.hr" />
        <li class="nav-item nav-category" *ngIf="item.isTitle">{{ item.label }}</li>
        <li class="nav-item" *ngIf="!item.isTitle" >

          <a class="nav-link" *ngIf="hasItems(item)" href="javascript:void(0);">
            <!-- <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i> -->
            <i class="{{item.icon}}" *ngIf="item.icon"></i>
            <span class="link-title"> {{ item.label }}</span>
            <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
          </a>

          <a class="nav-link nav-link-ref" [routerLink]="item.link" *ngIf="!hasItems(item) && !item.action"
            [attr.data-parent]="item.parentId">
            <!-- <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i> -->
            <i class="{{item.icon}}" *ngIf="item.icon"></i>
            <span class="link-title"> {{ item.label }}</span>
            <span class="badge badge-pill badge-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text}}</span>
          </a>
          <a class="nav-link nav-link-ref" (click)="call(item.action)" *ngIf="!hasItems(item) && item.action"
            [attr.data-parent]="item.parentId">
            <!-- <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i> -->
            <i class="{{item.icon}}" *ngIf="item.icon"></i>
            <span class="link-title"> {{ item.label }}</span>
            <span class="badge badge-pill badge-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text}}</span>
          </a>

        </li>
      </ng-container>

    </ul>


  </div>
</nav>
