import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

declare var require: any;
const Quote = require('inspirational-quotes');


@Component({
  selector: 'app-profile-span',
  templateUrl: './profile-span.component.html',
  styleUrls: ['./profile-span.component.css']
})
export class ProfileSpanComponent implements OnInit {

  user: any;
  quote = 'Work hard, dream big!';

  constructor() { }

  ngOnInit(): void {
    this.quote = Quote.getRandomQuote();
    this.user = AuthService.getUserObject();
  }

}
