import { Routes } from '@angular/router';
import { BaseComponent } from '../pages/layout/base/base.component';
import { AuthMiddleware } from '../middlewares/auth';


const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthMiddleware],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../pages/time-track-dashboard/time-track-dashboard.module').then(m => m.TimeTrackDashboardModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('../pages/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'resources',
        loadChildren: () => import('../pages/resources/resources.module').then(m => m.ResourcesModule)
      },
      {
        path: 'userDocuments',
        loadChildren: () => import('../pages/user-documents/user-documents.module').then(m => m.UserDocumentsModule)
      },
      {
        path: 'releases',
        loadChildren: () => import('../pages/releases/releases.module').then(m => m.ReleasesModule)
      },
      {
        path: 'projectRelease',
        loadChildren: () => import('../pages/project-releases/project-releases.module').then(m => m.ProjectReleasesModule)
      },
      {
        path: 'releaseDetails',
        loadChildren: () => import('../pages/release-detail/release-detail.module').then(m => m.ReleaseDetailModule)
      },
      {
        path: 'ideas',
        loadChildren: () => import('../pages/ideas/ideas.module').then(m => m.IdeasModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [],
    children: [
      {
        path: 'profile',
        loadChildren: () => import('../pages/profile/profile.module').then(m => m.ProfileModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthMiddleware],
    data: {
      permissions: [4]
    },
    children: [
      {
        path: 'users',
        loadChildren: () => import('../pages/users/users.module').then(m => m.UsersModule),
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthMiddleware],
    data: {
      permissions: [3]
    },
    children: [
      {
        path: 'adminResources',
        loadChildren: () => import('../pages/admin-resources/admin-resources.module').then(m => m.AdminResourcesModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthMiddleware],
    data: {
      permissions: [6]
    },
    children: [
      {
        path: 'user',
        loadChildren: () => import('../pages/user/user.module').then(m => m.UserModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ]
  }
];

export const backofficeRoutes = routes;
