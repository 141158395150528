import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { AuthService } from './services/auth.service';
import { ErrorService } from './services/error.service';
import { RestService } from './services/rest.service';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'hanna';
  user: any;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private restService: RestService,
    private errorService: ErrorService,
    private authService: AuthService,
    private socialAuthService: SocialAuthService
    ) { }

  ngOnInit(): void {
    // get return url from route parameters or default to '/'

    moment.tz.setDefault('Etc/UTC');

    this.socialAuthService.authState.subscribe((user) => {
      this.user = user;
      // console.log('SOCIAL AUTH STATE CHANGE! ', this.user);
      this.isLoading = true;
      if (this.user && this.user.email){
        this.restService.post('/api/auth/googleLogin', {idToken : this.user.idToken}, []).subscribe((resp: any) => {
            // console.log(resp);
            this.isLoading = false;

            this.authService.login(resp.data);
            this.router.navigate(['/']);

          },
          (error: any) => {
            console.log('ERROR LOGIN!!!', error);
            this.isLoading = false;
            this.errorService._error('warning', 'login error', error.message);
            this.router.navigate(['/auth/login/', true]);
          });
      }else{
        this.isLoading = false;
        this.router.navigate(['/auth/login/', true]);
      }
    });
  }
}
