import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    ) { }

  apiUrl: any = environment.apiUrl;
  endpoint: any;

  public static getToken(): string | false{
    if (localStorage.getItem(environment.localStorageFlagName) && localStorage.getItem('token')){
      return localStorage.getItem('token');
    }
    return false;
  }

  public static getUserObject(): any{
    if (localStorage.getItem(environment.localStorageFlagName) && localStorage.getItem('user')){
      return JSON.parse(localStorage.getItem('user'));
    }
    return false;
  }

  public static getUserId(): string | false{
    if (localStorage.getItem(environment.localStorageFlagName) && localStorage.getItem('userId')){
      return localStorage.getItem('userId');
    }
    return false;
  }

  public static setUserObject(user: any): void{
    if (user){
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  public static userHasPermission(user: any, permissionId: any): boolean{
    return user.roles.filter((role: any) => {
      return role.rolePermissions.filter((permission: any) => {
        return permission.permissionId === permissionId;
      }).length > 0;
    }).length > 0;
  }

  public static userCanViewResource(user: any, resourceId: any): boolean{
    if (user.resourceUsers && user.resourceUsers.length > 0){
      return user.resourceUsers.filter((resourceUser: any) => {
        return resourceUser.resourceId === resourceId;
      }).length > 0;
    }else{
      return false;
    }
  }

  userHasPermission(user: any, permissionId: any): boolean{
    return user.roles.filter((role: any) => {
      return role.rolePermissions.filter((permission: any) => {
        return permission.permissionId === permissionId;
      }).length > 0;
    }).length > 0;
  }

  isLoggedIn(): boolean{
    return (localStorage.getItem(environment.localStorageFlagName)) ? true : false;
  }

  login(data): void{
    localStorage.setItem(environment.localStorageFlagName, 'true');
    localStorage.setItem('userId', data.user.id);
    localStorage.setItem('token', data.user.token);
    localStorage.setItem('roleId', data.user.roleId);
    localStorage.setItem('user', JSON.stringify(data.user));
  }

  logout(): void{
    localStorage.removeItem('token');
    localStorage.removeItem(environment.localStorageFlagName);
    localStorage.removeItem('userId');
    this.router.navigate(['/auth/login']);
  }

}
