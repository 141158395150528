import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { defer, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(
    private http: HttpClient,
    ) { }

  apiUrl: any = environment.apiUrl;

  public static getApiUrl(): string{
    return environment.apiUrl;
  }

  private handleError(error: HttpErrorResponse): any {
    // if (error.error instanceof ErrorEvent) {
    //   // console.error('An error occurred:', error.error.message);
    // } else {
    //   // console.error(
    //   //   `Backend returned code ${error.status}, ` +
    //   //   `body was: ${error.error}`);
    // }
    return throwError(error);
  }

  private extractData(res: any): any {
    const body = res;
    return body || { };
  }

  post(endpoint: string, data: any, options: any): Observable<any> {
    return this.http.post(this.apiUrl + endpoint, data, options).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  patch(endpoint: string, data: any, options: any): Observable<any> {
    return this.http.patch(this.apiUrl + endpoint, data, options).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  delete(endpoint: string, data: any): Observable<any> {
    return this.http.delete(this.apiUrl + endpoint, data).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  get(endpoint: string): Observable<any> {
    return this.http.get(this.apiUrl + endpoint).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  postFile(endpoint: string, data: any, options: any): Observable<any>{
    return defer( () => axios.post(this.apiUrl + endpoint, data, { params: options, headers: { Authorization : AuthService.getToken() } }))
    .pipe(
      map(result => result.data),
      catchError(this.handleError)
      );
  }

}
