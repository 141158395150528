import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private socialAuthService: SocialAuthService

        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status) && this.authService.isLoggedIn()) {
                // auto logout if 401 or 403 response returned from api
                // this.authService.logout();
                this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID).then(() => {
                    // Any post-refresh actions if needed
                  },
                  (error: any) => {
                    console.log('Social login refresh failed!', error);
                    this.authService.logout();
                  });
            }

            const error = (err && err.error && err.error.message) || err.statusText;
            console.error(err);
            return throwError(err);
        }));
    }
}
