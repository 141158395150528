import { EventEmitter, Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { RestService } from './rest.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  navChange: EventEmitter<void> = new EventEmitter();
  constructor(
      private restService: RestService,
      private errorService: ErrorService
    ) {
     this.navChange.subscribe(() => {
       this.getMenuItemBadges();
     });
    }

  emitNavChangeEvent(): void{
    this.navChange.emit();
  }

  getNavChangeEmitter(): EventEmitter<void> {
    return this.navChange;
  }

  getMenuItemBadges(): any {
    this.restService.get('/api/users/getMenuItemsBadges').subscribe((resp: any) => {
      // console.log("get Menu items badge = ",resp.data);
      return {
        myDocuments: resp.data.myDocuments,
        myResources: resp.data.myResources,
        myTimeTracks: resp.data.myTimeTracks,
        myNewIdeas: resp.data.myNewIdeas
      };
    },
    (error: any) => {
      console.log(error);
      this.errorService._error('warning', 'menuItemBadges - get() error', error.message);
    });
  }

}
