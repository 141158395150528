import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { RestService } from 'src/app/services/rest.service';
import { IconService } from 'src/app/services/icon.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  user: any;
  apiUrl: any;
  unreadNotifications: any;
  size80 = 80;
  size30 = 30;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private restService: RestService,
    private router: Router,
    public iconService: IconService
  ) { }

  ngOnInit(): void {
    this.user = AuthService.getUserObject();
    this.apiUrl = environment.apiUrl;
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e): void {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e): void {
    e.preventDefault();
    localStorage.removeItem(environment.localStorageFlagName);

    if (!localStorage.getItem(environment.localStorageFlagName)) {
      this.router.navigate(['/auth/login']);
    }
  }

  editProfile(): void{
    this.router.navigate(['/profile']);
  }

  timeAgo(notification): string{
    return moment(notification.createdAt).locale('es').fromNow();
  }

}
