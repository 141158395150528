import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthMiddleware implements CanActivate {
  constructor(
    private router: Router
    ) {}

  checkProfile(user: any): boolean{
    if (
      user.nif &&
      user.phone &&
      user.address &&
      user.cp &&
      user.location &&
      user.province &&
      user.country &&
      user.ccc &&
      user.profile
    ){
      return true;
    }
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem(environment.localStorageFlagName)) {
      // logged in so continue
      const user = AuthService.getUserObject();
      if (user.active && route.data && route.data.permissions && route.data.permissions.length > 0){
        for (const r of route.data.permissions){
          console.log("Check for role id = "+r);
          if (AuthService.userHasPermission(user, r)){
            if (this.checkProfile(user)){
              return true;
            }else{
              this.router.navigate(['/profile'], { queryParams: { error: 1 } });
              return false;
            }
          }
        }
      }else{
        if (this.checkProfile(user)){
          return true;
        }else{
          this.router.navigate(['/profile'], { queryParams: { error: 1 } });
          return false;
        }
      }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
